/* eslint-disable camelcase */
import React, { useCallback, useEffect } from 'react';
import moment from 'moment';
import { Formik, Form, Field } from 'formik';
import clsx from 'clsx';
import { bookSlot } from '../../../../../services/appointment';
import slotStyles from './slot_booking_modal.module.scss';
import useLoader from '../../../../../context/utilities/useLoader';
import { useModal } from '../../../../../context/modal';
import { validations } from '../../../../../helperFunctions';
import { TextField } from '../../../../components/inputs';
import { formValidations } from '../../../../helpers/moduleHelper';
import Options from './Options';
import usePendoUpdateUrl from '../../../../../hooks/usePendoUpdateUrl';

const appointmentTypes = [
  {
    label: 'Virtual',
    name: 'virtual',
    value: 'virtual',
  },
  {
    label: 'In Person',
    name: 'in_person',
    value: 'in_person',
  },
];
const SlotBookingModal = ({
  slot,
  closeModal,
  patient,
  encounter,
  survey_id,
  stringValues,
  memberSelfServeRecord,
  filtersApplied = {},
  siteData,
  params,
  referralInstruction,
  allowTextNotification,
  locale,
  dsboConfirmationEnabled,
  dsboCancellationConfirmationEnabled,
  cancellationPollingTimeout,
}) => {
  const getAppointmentType = () => {
    let type = '';
    if (slot.virtual && !slot.in_person) {
      type = 'virtual';
    } else if (!slot.virtual && slot.in_person) {
      type = 'in_person';
    } else if (!filtersApplied.in_person) {
      type = 'virtual';
    } else if (!filtersApplied.virtual) {
      type = 'in_person';
    } else if (slot.provider.out_of_travel_radius) {
      type = 'virtual';
    }
    return type;
  };

  const bookedStatusRef = React.useRef(false);
  const { showLoader, hideLoader } = useLoader({
    key: 'SlotBookingModal',
    displayMessage: stringValues.book_appt_loader_text,
  });
  const { alertDialogue } = useModal();
  const bookAppointment = useCallback(
    (values) => {
      // eslint-disable-next-line no-unused-vars
      const { start_date, end_date } = slot;
      showLoader();
      bookSlot({
        slotId: slot.id,
        dsboConfirmationEnabled,
        dsboCancellationConfirmationEnabled,
        cancellationPollingTimeout,
        provider_id: slot.provider.id,
        appointment: {
          start_date,
          duration: 60,
          is_virtual: values.appointment_type === 'virtual',
          site: slot.provider.site,
          site_id: slot.provider.site_id,
          visit_type_id: slot.visit_type_id,
          hasIdentifiers: slot.identifiers,
        },
        email: values.email,
        sms: values.sms,
        patient_id: patient.id,
        encounter_id: encounter?.id,
        encounterToReschedule: encounter?.id && {
          id: encounter.id,
          hasIdentifiers: encounter.hasIdentifiers,
        },
        member_self_serve_record: memberSelfServeRecord,
        survey_id,
        params,
      })
        .then(async (res) => {
          if (res.data?.redirect_path) {
            bookedStatusRef.current = true;
            if (res.data?.redirect_path.includes('/call_centers/providers')) {
              hideLoader();
              // TODO: We need to provide better error messaging here for each case. And instead of a reload, we should call the API again.
              await alertDialogue(
                'We apologize for the inconvenience. Something unexpected occurred. Please try again.',
              );
              window.location.reload();
            } else {
              window.location.href = res?.data?.redirect_path;
            }
          } else {
            hideLoader();
            closeModal(res);
          }
        })
        .catch(async (err) => {
          hideLoader();
          if (err?.message === 'Could not cancel the external appointment.') {
            await alertDialogue(
              'We’re sorry, but your original appointment can no longer be modified by Lucet. Please contact the provider directly for any appointment changes needed.',
            );
            window.location.href = `/member_self_serve/${memberSelfServeRecord}`;
          } else if (err?.message) {
            await alertDialogue(err.message);
          } else {
            alert(err);
          }
        });
    },
    [slot, survey_id, patient.id],
  );

  useEffect(() => {
    const sendUnlockBeacon = () => {
      const payload = new FormData();
      payload.append('locked_for_id', patient.id);
      payload.append('locked_for_type', 'Patient');
      if (
        bookedStatusRef.current === false &&
        navigator &&
        typeof navigator.sendBeacon === 'function'
      ) {
        navigator.sendBeacon(`/slots/${slot.id}/unlock/`, payload);
      }
    };

    window.addEventListener('beforeunload', sendUnlockBeacon);
    return () => {
      window.removeEventListener('beforeunload', sendUnlockBeacon);
    };
  }, [slot.id, patient.id]);

  usePendoUpdateUrl(`/modal/slot_booking_modal/${slot.id}`);

  let postFixTitle = '';
  if (referralInstruction && referralInstruction.name) {
    if (!encounter?.start_date) postFixTitle += ' |';
    postFixTitle += ` ${referralInstruction.name}`;
  }

  const bothPhoneEmailAreNotRequired = () =>
    slot.provider?.email_required === false &&
    slot.provider?.phone_required === false;

  const validate = (values) => {
    const errors = {};
    const emailAndPhoneNotRequired = bothPhoneEmailAreNotRequired();
    const requiredError = formValidations.fieldErrorMessage(locale);
    const emailOrPhoneError = formValidations.emailOrPhoneErrorMessage(locale);

    if (
      siteData.show_warning_message === true &&
      !values.out_of_state_confirmation
    ) {
      errors.out_of_state_confirmation = requiredError;
    }

    if (validations.required(values.appointment_type)) {
      errors.appointment_type = requiredError;
    }
    const {
      email: emailValidation,
      phone: phoneValidation,
      required: requiredValidation,
    } = validations;
    const emailEmpty = requiredValidation(values.email);
    const phoneEmpty = requiredValidation(values.sms);

    if (slot.provider?.email_required === true) {
      if (emailEmpty) errors.email = requiredError;
    }
    if (slot.provider?.phone_required === true) {
      if (allowTextNotification) if (phoneEmpty) errors.sms = requiredError;
    }
    if (emailAndPhoneNotRequired === true && emailEmpty && phoneEmpty) {
      if (allowTextNotification) {
        errors.sms = emailOrPhoneError;
      } else {
        errors.email = requiredError;
      }
    }

    if (!errors.email) {
      const emailError = emailValidation(values.email, locale);
      if (emailError) errors.email = emailError;
    }
    if (allowTextNotification) {
      if (!errors.sms) {
        const phoneError = phoneValidation(values.sms, locale);
        if (phoneError) errors.sms = phoneError;
      }
    }
    return errors;
  };

  const handleSubmit = (values) => {
    bookAppointment(values);
  };

  const { notification_details } = patient || {};
  const { sms, email } = notification_details || {};

  return (
    <Formik
      enableReinitialize
      validateOnMount
      validateOnChange
      validate={validate}
      initialValues={{
        email: email?.contact_value || '',
        sms: allowTextNotification ? sms?.contact_value || '' : '',
        appointment_type: getAppointmentType() || '',
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          handleSubmit(values);
          setSubmitting(false);
        }, 100);
      }}
    >
      {({ values, isValid, isSubmitting }) => (
        <Form>
          <div
            className={`${slotStyles.slotContainer} align-center col-center mss-slot-booking-modal-wrapper`}
          >
            {siteData.show_warning_message === true && (
              <div className={`${slotStyles.warning} padding-md`}>
                <span
                  className={clsx(
                    slotStyles.outOfStateConfirmationCheckBox,
                    'checkbox-wrap',
                  )}
                >
                  <Field
                    id="out_of_state_confirmation"
                    type="checkbox"
                    name="out_of_state_confirmation"
                  />
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="out_of_state_confirmation">
                    <span className="bold">{stringValues.warning}: </span>
                    {stringValues.out_of_state_confirmation}
                  </label>
                </span>
              </div>
            )}
            <div className="padding-lg padding-left-lg padding-right-lg">
              <h1 className="prompt col-center" id="dialog_head">
                {encounter?.start_date
                  ? `Reschedule ${moment(encounter?.start_date).format(
                      'M/D/YYYY h:mma',
                    )} Appointment to:${postFixTitle}`
                  : `${stringValues.book_appointment}${postFixTitle}`}
              </h1>
              <br />
              <div
                id="dialog_desc"
                className={
                  !slot.in_person || !slot.provider ? 'margin-bottom-xl' : ''
                }
              >
                {slot.provider.intake_appt_role === 'coordinator' && (
                  <p className="color-plum">
                    <strong>
                      <i>{stringValues.intake_coordinator_info}</i>
                    </strong>
                  </p>
                )}
                <h2 className={`${slotStyles.slotText} prompt`}>
                  {
                    stringValues[
                      moment(slot.start_date).format('dddd').toLowerCase()
                    ]
                  }
                  {', '}
                  {
                    stringValues[
                      moment(slot.start_date).format('MMMM') === 'May'
                        ? 'may_full'
                        : moment(slot.start_date).format('MMMM').toLowerCase()
                    ]
                  }{' '}
                  {moment(slot.start_date).format('D')}
                  <br />
                  {moment(slot.start_date).format('h:mm a')}
                </h2>
                <div className={`${slotStyles.fieldsetWrapper}`}>
                  <fieldset
                    className={`${slotStyles.fieldset} flex-wrap-children justify-content-center`}
                  >
                    <Options
                      name="appointment_type"
                      options={appointmentTypes || []}
                      slot={slot}
                      filtersApplied={filtersApplied}
                      stringValues={stringValues}
                      slotStyles={slotStyles}
                      value={values.appointment_type}
                    />
                  </fieldset>
                  <div>
                    {slot.provider?.email_required === true &&
                      slot.provider?.phone_required === true &&
                      allowTextNotification && (
                        <div className={slotStyles.validationWarningMessage}>
                          <span>
                            Email AND cell number are required for this
                            appointment
                          </span>
                        </div>
                      )}
                    <div className={slotStyles.textFieldsWrapper}>
                      <TextField
                        name="email"
                        type="text"
                        placeholder="Enter email"
                        mask="email"
                      />
                      {allowTextNotification && (
                        <TextField
                          name="sms"
                          type="text"
                          placeholder="Enter cell number"
                          mask="phone"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="button-section margin-top-md">
                <button
                  type="button"
                  className={`${slotStyles.slotBtn} cancel-btn`}
                  onClick={closeModal}
                >
                  {stringValues.cancel}
                </button>
                <button
                  type="submit"
                  className={`${slotStyles.slotBtn} success-btn`}
                  disabled={isValid !== true || isSubmitting === true}
                >
                  {encounter?.start_date
                    ? 'Reschedule Appointment'
                    : stringValues.book_appointment}
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
export default SlotBookingModal;
